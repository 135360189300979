import React from "react";
import useSmoothScroll from "../hooks/useSmoothScroll"

const Banner = () => {
  const scrollToSection = () => {
    const section = document.getElementById("s1"); // ID da seção de destino
    if (section) {
      section.scrollIntoView({ behavior: "smooth" }); // Rolagem suave
    }
  };

  return (
    <header id="banner" className="background banner d-flex justify-content-center align-items-center vh-100 bg-primary text-white" role="banner">
      <div className="text-center">
        <h1 className="display-4">Bem-vindo ao Ruan o Maker!</h1>
        <p className="lead"> Aqui, você embarca em uma jornada pelo fascinante mundo da tecnologia. 
          Com uma abordagem inovadora e acessível, meu objetivo é desmistificar a tecnologia e mostrar como ela pode ser integrada em nossas vidas de maneira prática e inspiradora.
        </p>
        <a href="#s1" onClick={scrollToSection} aria-label="Ir para a seção 1" style={{color:"white"}}>
          <svg
            className="arrow" // Adiciona a classe para animação
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
           
            viewBox="0 0 24 24"
            aria-hidden="true" // O SVG não precisa de descrição adicional
          >
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <polyline points="19 12 12 19 5 12"></polyline>
          </svg>
        </a>
      </div>
    </header>
  );
};

export default Banner;
