// src/App.js
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import "./assets/vendor/bootstrap/css/bootstrap.min.css";
import "./App.css";

import Banner from "./components/Banner";
import Dev from "./components/Dev";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Products from "./pages/Products";
import News from "./components/News";
import About from "./pages/About";
import ThemeSwitcher from "./components/ThemeSwitcher";
import "./styles/global.css"; // Estilos globais

const _Banner = () => {
  const location = useLocation();
  return location.pathname === "/" ? <Banner /> : null;
};

function App() {
  const [activeSection, setActiveSection] = useState(null);

  // Função para monitorar a posição do scroll e adicionar/remover a classe fixed-top
  const handleScroll = () => {
    const sections = document.querySelectorAll("section"); // Seleciona todas as seções
    const scrollPosition = window.scrollY;
    const nbar = document.getElementById("nav");

    sections.forEach((section) => {
      const sectionTop = section.offsetTop;
      const sectionHeight = section.offsetHeight;
      const sectionId = section.getAttribute("id");

      if (
        scrollPosition >= sectionTop - sectionHeight / 3 &&
        scrollPosition < sectionTop + sectionHeight - sectionHeight / 3
      ) {
        setActiveSection(sectionId); // Define a seção ativa
        console.log(`Seção ativa: ${sectionId}`); // Exibe a seção ativa no console
      }
    });

    // Adiciona ou remove a classe 'fixed-top' com base na posição do scroll
    if (scrollPosition > 50) {
      nbar.classList.add("fixed-top");
    } else {
      nbar.classList.remove("fixed-top");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // O array vazio garante que o efeito seja executado apenas uma vez, ao montar o componente

  return (
    <Router>
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="description"
          content="Portfólio de Ruan Santana, desenvolvedor Full-Stack, exibindo projetos inovadores em tecnologia."
        />
        <meta
          name="keywords"
          content="desenvolvedor, portfólio, projetos, produtos, Laravel, React, Node.js, API"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          property="og:title"
          content="Portfólio do Ruan Santana - Desenvolvedor Full-Stack"
        />
        <meta
          property="og:description"
          content="Explore projetos inovadores e soluções personalizadas criadas por Ruan Santana."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.seuportifolio.com" />
        <meta
          property="og:image"
          content="https://www.seuportifolio.com/imagem.png"
        />
        <title>Portfólio do Ruan Santana - Desenvolvedor Full-Stack</title>
      </Helmet>

      <header>
        <Dev />
        {/*   <_Banner />
        <Navbar /> */}
      </header>

      {/* Seção Principal */}
      {/* <main className="container my-5" id="main-content">
        <ThemeSwitcher />
        <div className="row">
          <div className="col">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/products" element={<Products />} />
              <Route path="/news" element={<News />} />
              <Route path="/about" element={<About />} />
            </Routes>
          </div>
        </div>
      </main> */}

      {/* Inclui o Footer */}
      {/*  <footer className="mt-auto">
        <Footer />
      </footer> */}
    </Router>
  );
}

export default App;
