import React from "react";

const Dev = () => {
  
  return (
    <header
      id="indev"
      className="indev banner d-flex justify-content-center align-items-center vh-100 bg-primary text-white"
      role="banner"
    >
      <div className="text-center">
        <h1 className="display-4">Bem-vindo ao Ruan o Maker!</h1>
        <p className="lead">
          {" "}
          Aqui, você embarca em uma jornada pelo fascinante mundo da tecnologia.
          Com uma abordagem inovadora e acessível, meu objetivo é desmistificar
          a tecnologia e mostrar como ela pode ser integrada em nossas vidas de
          maneira prática e inspiradora.
        </p>
        <p className="lead">
          Desculpe pelo inconveniente! Estamos em desenvolvimento e voltaremos
          em breve. Agradecemos sua paciência!
        </p>
      </div>
    </header>
  );
};

export default Dev;
